import React from 'react'
import Post from '../Post'
import './style.scss'

class PeriodTemplateDetails extends React.Component {
    render() {
        const items = []
   
        const { displayMonth, displayYear } = this.props.pageContext;

        const posts = this.props.data.allMarkdownRemark.edges
        posts.forEach(post => {
            items.push(<Post data={post} key={post.node.fields.slug} />)
        })

        return (
            <div className="content">
                <div className="content__inner">
                    <div className="page">
                        <h1 className="page__title" id="period_title_area">
                            <div className="period_title">
                                <span className="periodMark"></span>
                                &quot;
                                {displayYear}年{displayMonth}月の記事
                                &quot;
                            </div>
                            <div className="number_of_post_periodpage">{items.length}件の記事があります</div>
                        </h1>
                    <div className="page__body">{items}</div>
                </div>
                </div>
            </div>
        )
    }
}

export default PeriodTemplateDetails